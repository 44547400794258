import request from '../http'
import {managerUrl} from '../api'

//首页菜单自定义版块
export function customList(data){
  return request({
    url:managerUrl + "/carton/menu/info/custom",
    method:'post',
    data
  })
}

//首页菜单自定义排序
export function customSort(data){
  return request({
    url:managerUrl + "/carton/menu/info/custom/sort",
    method:'post',
    data
  })
}

//菜单自定义到首页
export function customAdd(data){
  return request({
    url:managerUrl + "/carton/menu/info/custom/index",
    method:'post',
    data
  })
}

//自定义菜单移除
export function customDel(data){
  return request({
    url:managerUrl + "/carton/menu/info/custom/del",
    method:'post',
    data
  })
}

//首页待处理列表
export function pendingList(data) {
    return request({
        url: managerUrl + "/carton/report/web/index",
        method: 'post',
        data
    })
}