<template>
  <div class="queryBox dp_f">
    <div class="board-query dp_f">
      <el-select
          placeholder="请选择仓库"
          class="no-boder-select"
          v-model="formDataLocal.stockId"
          popper-class="no-border-select-popper"
      >
        <el-option
            v-for="item in sotckList"
            :key="item.stockId"
            :label="item.stockName"
            :value="item.stockId"
        />
      </el-select>
      <div class="timeSelect">
        <div class="item cur_p" :class="tabChecked===0?'active':''" @click='tabCheck(0)'>本月</div>
        <div class="item cur_p" :class="tabChecked===1?'active':''" @click='tabCheck(1)'>本季度</div>
        <div class="item cur_p" :class="tabChecked===2?'active':''" @click='tabCheck(2)'>本年度</div>
      </div>
    </div>
    <div class="btnBox dp_f">
      <el-radio-group v-model="tabChecked" @change="quickCheckTime" class="mr_16">
        <el-radio-button :label="3">昨天</el-radio-button>
        <el-radio-button :label="4">今天</el-radio-button>
      </el-radio-group>
      <el-date-picker
          v-model="formDataLocal.time"
          type="daterange"
          unlink-panels
          class="w280 mr_16"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="large"
          @change="changeTime"
      />
      <el-button type="primary"
                 @click='handleSearch'
      >
        <el-icon>
          <Search/>
        </el-icon
        >
      </el-button>
    </div>
  </div>
</template>

<script setup>
import {computed, getCurrentInstance, reactive, ref, watch} from "vue";
import {stockList} from "@/utils/api/procurement/stock.js";
import moment from "moment/moment";
import {Search} from "@element-plus/icons-vue";

const {proxy} = getCurrentInstance();
const tabChecked = ref(-1)
const props = defineProps({
  formData: {
    type: Object,
    default: () => {
    },
  },
  quickSearchYear: {
    type: Boolean,
    default: false,
  },
  quickSearchYesterday: {
    type: Boolean,
    default: false,
  },
})
const formDataLocal = reactive({
  time: [],
  stockId: '',
})

const emit = defineEmits(["update:formData", "search"])
const sotckList = ref([])
const getStockListData = async () => {
  const res = await stockList();
  if (res.code === 0) {
    if (res.data?.list?.length === 1) {
      formDataLocal.stockId = res.data.list[0].stockId;
      sotckList.value = res.data.list;
    } else if (res.data?.list?.length && res.data?.list?.length > 1) {
      sotckList.value = res.data.list
      sotckList.value.unshift({stockId: "", stockName: "全部仓库"});
    }
  } else {
    proxy.$message.error(res.msg);
  }
};
const quickCheckTime = () => {
  if (tabChecked.value === 3) {
    formDataLocal.time = getTime(1, 'days', 'yesterday')
  } else if (tabChecked.value === 4) {
    formDataLocal.time = getTime(0, 'days', 'yesterday')
  }
}

const timeType = computed(() => {
  let timeType
  switch (tabChecked.value) {
    case 1:
      timeType = 2
      break;
    case 2:
      timeType = 2
      break;
    default:
      timeType = 1
      break;
  }
  return timeType
})

const tabCheck = (num) => {
  if (num === tabChecked.value) return
  switch (num) {
    case 0:
      formDataLocal.time = [moment().startOf('months').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
      break;
    case 1:
      formDataLocal.time = [moment().startOf('quarters').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
      break;
    case 2:
      formDataLocal.time = [moment().startOf('year'), moment().format('YYYY-MM-DD')]
      break;
    default:
      break;
  }
  tabChecked.value = num
}

const handleSearch = () => {
  emit("search");
}

const changeTime = (e) => {
  tabChecked.value = -1
  if (e) {
    formDataLocal.time = [moment(e[0]).format('yyyy-MM-DD') + ' 00:00:00', moment(e[1]).format('yyyy-MM-DD') + ' 23:59:59']
  } else {
    formDataLocal.time = []
  }
}

function getTime(num, date, type) {
  let star = ''
  let end = ''
  if (type === 'yesterday') {
    star = moment(new Date()).subtract(num, date)._d
    star = moment(star).format('yyyy-MM-DD') + ' 00:00:00'
    end = moment(new Date()).subtract(num, date)._d
    end = moment(end).format('yyyy-MM-DD') + ' 23:59:59'
  } else {
    star = moment(new Date()).format('yyyy-MM-DD')
    end = moment(new Date()).subtract(num, date)._d
    end = moment(end).format('yyyy-MM-DD')
  }
  return [star, end]
}

watch(
    () => formDataLocal,
    (val) => {
      emit("update:formData", {...val, timeType: timeType.value});
    }, {
      deep: true
    }
);

getStockListData();
tabCheck(0)

</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: 'BoardHeader',
})
</script>

<style lang="scss" src="./index.scss" scoped>

</style>