<template>
  <board-box size="1">
    <div class="board_title dp_f">
      <div class="title">销售趋势</div>
      <i class="collection iconfont icon-a-ziyuan16 cur_p" @click='like()' v-if='isLike'/>
      <i class="collection iconfont icon-a-ziyuan15 cur_p" @click='like(true)' v-else/>
    </div>
    <div :id="eChart" class="charts-container" style="width: 100%; height: 220px">
    </div>
  </board-box>
</template>

<script setup>
import BoardBox from "@components/common/boardContent/index.vue";
import {getCurrentInstance, nextTick, onMounted, ref, toRefs} from "vue";
import * as echarts from "echarts";
import {outStockTrend} from "@utils/api/outStock/census"
import moment from "moment/moment";
import {useEventListener} from '@utils/tool/event'
import {debounce} from "lodash"

let myChart = null;

const props = defineProps({
  formData: {
    type: Object,
    default: () => {
    },
  },
  isLike: {
    type: Boolean,
    default: false,
  },
});
const {formData, isLike} = toRefs(props);
const eChart = ref("eChart" + Date.now() + Math.random());
const {proxy} = getCurrentInstance();
const emit = defineEmits(["like"])
const moneyText = ref('')

const initEchart = (list = []) => {
  moneyText.value = ''
  if (list.findIndex(item => item.salePrice > 10000000) !== -1) {
    moneyText.value = ' 万元'
  } else {
    moneyText.value = ' 元'
  }
  //销售成本
  let sellList = [];
  //下表
  let indexList = [];
  list.forEach((item) => {
    let sell = proxy.$filters.toYuan(item.salePrice, moneyText.value === ' 万元' ? '万元' : '元')
    sellList.push({
      trueValue: item.salePrice,
      value: sell
    });
    indexList.push(item.index)
  });
  let chartDom = document.getElementById(eChart.value);
  if (!chartDom) return
  myChart = echarts.init(chartDom);
  let option = {
    title: {
      show: indexList.length === 0,   // 没数据才显示
      text: '{A|}\n{B|暂无数据}',
      //主标题文字样式
      textStyle: {
        color: "#A9A9B1",
        fontSize: 14,
        //自定义样式
        rich: {
          "A": {
            backgroundColor: {
              //在这里填写背景图片路径
              image: require('@img/index/noGoods.png')
            },
            //设置图片宽高
            height: 64,
            width: 98,
          },
          "B": {
            padding: [20, 0, 0, 0],
            color: "#A9A9B1",
            fontSize: 14,
            fontWeight: 600,
          }
        }
      },
      left: "center",
      top: "center"
    },
    tooltip: {
      trigger: 'axis',
      formatter: function (params) {
        const index1 = params.findIndex(item => item.componentIndex === 0); //销售金额
        const num1 = index1 !== -1 ? proxy.$filters.toYuan(params[index1].data.trueValue) : null;
        const text1 = index1 !== -1 ? params[index1].seriesName : null;
        return `<div style='color: #161829;font-size: 18px;text-align:center'>${num1}</div><div style='color: #AAAAB2;font-size: 12px;margin-top:2px'>${text1}</div>`;
      }
    },
    xAxis: {
      type: "category",
      data: indexList,
      //横向坐标
      textBorderType: [5, 10],
      textBorderDashOffset: 0,
      show: indexList.length !== 0,
      axisLabel: {
        color: '#AAAAB2',
        interval: 0,
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          color: '#AAAAB2',
        }
      },
    },
    yAxis: {
      type: "value",
      borderWidth: 100,
      splitLine: {
        lineStyle: {
          type: [5, 8],
          color: "#E8EAED"
        }
      },
      axisLabel: {
        color: '#AAAAB2',
        formatter: function (value) {
          // value大于1000时除以1000并拼接k，小于1000按原格式显示
          if (value >= 1000 || value <= -1000) {
            value = (value / 1000) + 'k' + moneyText.value;
          } else {
            value = value + moneyText.value;
          }
          return value;
        },
      },
    },
    grid: {
      top: 30,
      left: 70,
      right: 10,
      bottom: '10%',
    },
    dataZoom: [
      {
        // 设置滚动条的隐藏与显示
        show: indexList.length !== 0,
        // 设置滚动条类型
        type: "slider",
        // 设置背景颜色
        backgroundColor: "rgba(0,0,0,0)",
        // 设置选中范围的填充颜色
        fillerColor: "rgba(0,0,0,0)",
        // 设置边框颜色
        borderColor: "rgba(0,0,0,0)",
        moveHandleSize: 6,
        moveHandleStyle: {
          color: "#E8EAED",
          borderColor: "#E8EAED",
          borderCap: "round",
          borderJoin: "round",
          borderDashOffset: 20
        },
        // 是否显示detail，即拖拽时候显示详细数值信息
        showDetail: false,
        // 数据窗口范围的起始数值
        startValue: 0,
        // 数据窗口范围的结束数值（一页显示多少条数据）
        endValue: 7,
        // empty：当前数据窗口外的数据，被设置为空。
        // 即不会影响其他轴的数据范围
        filterMode: "empty",
        // 设置滚动条宽度，相对于盒子宽度
        width: "90%",
        // 设置滚动条高度
        height: 4,
        // 设置滚动条显示位置
        left: "center",
        // 是否锁定选择区域（或叫做数据窗口）的大小
        zoomLoxk: true,
        // 控制手柄的尺寸
        handleSize: 0,
        // dataZoom-slider组件离容器下侧的距离
        bottom: 0,
      },
      {
        // 没有下面这块的话，只能拖动滚动条，
        // 鼠标滚轮在区域内不能控制外部滚动条
        type: "inside",
        // 滚轮是否触发缩放
        zoomOnMouseWheel: false,
        // 鼠标滚轮触发滚动
        moveOnMouseMove: true,
        moveOnMouseWheel: true,
      },
    ],
    // Declare several bar series, each will be mapped
    // to a column of dataset.source by default.
    series: [
      {
        name: '销售金额',
        type: 'bar',
        smooth: true,
        data: sellList,
        barWidth: 12,
        barGap: 0,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(0,0,0,0.3)'
          },
        },
        itemStyle: {
          borderRadius: [4, 4, 0, 0],
          color: '#48CB9F',
        },
      },
    ],
  };
  myChart.setOption(option);
}

const like = (type = false) => {
  emit("like", {
    type
  });
}
// 重置eChart大小
useEventListener(window, 'resize', debounce(() => {
  if (!myChart) return
  myChart.resize()
}, 100))

const getData = () => {
  if (!formData.value?.time[0]) return
  let obj = {
    stockId: formData.value.stockId || 0,
    beginTime: formData.value.time && formData.value.time[0] ? moment(formData.value.time[0]).format('YYYYMMDD') : 0,
    endTime: formData.value.time && formData.value.time[1] ? moment(formData.value.time[1]).format('YYYYMMDD') : 0,
    timeType: formData.value.timeType || 0,
  }
  outStockTrend(obj).then((res) => {
    if (res.code !== 0) {
      proxy.$message.error(res.msg)
      initEchart([])
      return
    }
    initEchart(res.data?.list || [])
  })
}
onMounted(() => {
  nextTick(() => {
    getData();
  })
})
</script>

<style lang="scss" src="./index.scss" scoped>

</style>