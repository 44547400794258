//饼图颜色配置
export const pieColor = [
    "#3859FF",
    "#48CB9F",
    "#57B0FF",
    "#FF7C5E",
    "#796EF0",
    "#FF6669",
    "#A96BFA",
    "#FF9F00",
    "#40E0D0",
    '#668CFB',
    '#90EE90',
    '#ADD8E6',
    '#FCD700',
    '#B38B6D',
    '#0073CF',
    '#CC7722',
    '#EEDC82',
    '#277A56',
    '#4C83E0',
    '#FFF44F',
    '#B284BE',
    '#61C4F2',
    '#005B9A',
    '#4CBB17',
    '#A34592',
    '#FF9999',
    '#FFD59A',
    '#B0E0E6',
    '#ED51C6',
    '#F88379'
];

//入库分类颜色
export const typeColor = [
    "#3859FF",
    "#FF7C5E",
    "#57B0FF",
    "#737EFF",
    "#48CB9F",
]