<template>
  <div v-bind="attrs" :class="['zh-board-box',size === '1'?'two':'one']">
    <slot></slot>
  </div>
</template>

<script setup>
import {toRefs, useAttrs} from 'vue'

const props = defineProps({
  size: {
    type: String,
    default: "1"
  }
})
const attrs = useAttrs()
const {size} = toRefs(props)
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: 'BoardBox',
  inheritAttrs: false,
})
</script>

<style lang="scss" scoped>
.board_title {
  font-size: 18px;
  color: #161829;

  .collection {
    color: #7D7E8E;
  }

  .en {
    margin: 0 0 0 6px;
  }

  .unit {
    font-size: 14px;
    color: #737480;
  }
}

.zh-board-box {
  padding: 32px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 16px;
  margin-bottom: 16px;
  height: 296px;

  &.two {
    width: 49.5%;
  }

  &.one {
    width: 100%;
  }
}
</style>