<template>
  <board-box size="1">
    <div class="board_title dp_f">
      <div class="title">销售出库</div>
      <i class="collection iconfont icon-a-ziyuan16 cur_p" @click='like()' v-if='isLike'/>
      <i class="collection iconfont icon-a-ziyuan15 cur_p" @click='like(true)' v-else/>
    </div>
    <div class="content dp_f">
      <div class="sell-right">
        <img src="@img/dataStatistics/sell.png" alt="位元灵感" class="sell-pic"/>
        <div class="sell-title">出库金额</div>
        <div class="sell-num" v-increase="orderInfo.stockPrice"></div>
      </div>
      <div class="sell-right">
          <img src="@img/outStock/board/outCount.png" alt="位元灵感" class="sell-pic"/>
          <div class="sell-title"> 出库数量</div>
          <div class="sell-num" v-increase="orderInfo.stockNum"></div>
      </div>
    </div>
  </board-box>
</template>

<script setup>
import {getCurrentInstance, reactive, toRefs} from 'vue';
import BoardBox from "@components/common/boardContent/index.vue";
import moment from 'moment'
import {outStockSale} from "@/utils/api/outStock/census.js";


const orderInfo = reactive({
  stockOrder: 0, // 入库订单
  stockNum: 0, // 入库数量
  stockPrice: 0, // 入库金额
})
const props = defineProps({
  formData: {
    type: Object,
    default: () => {
    },
  },
  isLike: {
    type: Boolean,
    default: false,
  },
});
const {formData, isLike} = toRefs(props);
const {proxy} = getCurrentInstance()
const emit = defineEmits(["like"])

const getData = () => {
  outStockSale({
    beginTime: formData.value.time && formData.value.time[0] ? moment(formData.value.time[0]).valueOf() : 0,
    endTime: formData.value.time && formData.value.time[1] ? moment(formData.value.time[1]).valueOf() : 0,
    stockId: formData.value.stockId || 0,
  }).then((res) => {
    if (res.code !== 0) {
      proxy.$message.error(res.msg)
      return
    }
    orderInfo.stockOrder = res.data?.stockOrder || 0;
    orderInfo.stockNum = res.data?.stockNum || 0;
    orderInfo.stockPrice = proxy.$filters.toYuan(res.data?.stockPrice || 0);
  })
};
getData()

const like = (type = false) => {
  emit("like", {
    type
  });
}
</script>
<style lang="scss" scoped src="./index.scss"></style>
<style lang="scss" scoped>
.content {
  width: 100%;
  margin-top: 30px;
  height: 184px;

  .sell-pic {
    width: 36px;
    height: 36px;
  }

  .sell-content {
    width: 100%;
  }
  .sell-title {
    color: $fsColor2;
    font-size: 14px;
    line-height: 1;
    margin-top: 32px;
  }


  .sell-right {
    width: 50%;

    .sell-num {
      font-size: 24px;
      margin-top: 18px;
    }
  }
}
</style>