import request from '@utils/http'

//出库合计
export function outStockTotal(data) {
    return request({
        url: "/carton/product/stock/out/census/total",
        method: 'post',
        data
    })
}

//类型占比
export function outStockType(data) {
    return request({
        url: "/carton/product/stock/out/census/type",
        method: 'post',
        data
    })
}

//产品排名
export function outStockProduct(data) {
    return request({
        url: "/carton/product/stock/out/census/product",
        method: 'post',
        data
    })
}

//客户排名
export function outStockPunter(data) {
    return request({
        url: "/carton/product/stock/out/census/punter",
        method: 'post',
        data
    })
}

//销售出库
export function outStockSale(data) {
    return request({
        url: "/carton/product/stock/out/census/sell",
        method: 'post',
        data
    })
}

//销售趋势
export function outStockTrend(data) {
    return request({
        url: "/carton/product/stock/out/census/trend",
        method: 'post',
        data
    })
}