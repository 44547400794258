<template>
  <div class="total board_box">
    <div class="board_title dp_f">
      <div class="title">出库合计</div>
      <i class="collection iconfont icon-a-ziyuan16 cur_p" @click='like()' v-if='isLike'/>
      <i class="collection iconfont icon-a-ziyuan15 cur_p" @click='like(true)' v-else/>
    </div>
    <div class="content dp_f cur_p">
      <div class="item item1">
        <div class="top" v-increase="orderInfo.stockNum">
        </div>
        <div class="bottom">
          出库数量
        </div>
      </div>
      <div class="item item3">
        <div class="top" v-increase="orderInfo.stockPrice">
        </div>
        <div class="bottom">
          出库金额
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {getCurrentInstance, reactive, toRefs, watch} from "vue";
import {outStockTotal} from "@/utils/api/outStock/census";
import moment from 'moment'

const props = defineProps({
  formData: {
    type: Object,
    default: () => {
    },
  },
  isLike: {
    type: Boolean,
    default: false,
  },
});
const {proxy} = getCurrentInstance();
const emit = defineEmits(["like"])
const {formData, isLike} = toRefs(props);
const orderInfo = reactive({
  stockOrder: 0, // 入库订单
  stockNum: 0, // 入库数量
  stockPrice: 0, // 入库金额
})
watch(
    () => formData.value,
    () => {
      getData()
    }, {
      deep: true
    }
);
const getData = () => {
  outStockTotal({
    beginTime: formData.value.time && formData.value.time[0] ? moment(formData.value.time[0]).valueOf() : 0,
    endTime: formData.value.time && formData.value.time[1] ? moment(formData.value.time[1]).valueOf() : 0,
    stockId: formData.value.stockId || 0,
  }).then((res) => {
    if (res.code !== 0) {
      proxy.$message.error(res.msg)
      return
    }
    orderInfo.stockOrder = res.data?.stockOrder || 0;
    orderInfo.stockNum = res.data?.stockNum || 0;
    orderInfo.stockPrice = proxy.$filters.toYuan(res.data?.stockPrice || 0);
  })
};
getData()
const like = (type = false) => {
  emit("like", {
    type
  });
}
</script>

<script>
export default {
  name: "total",
};
</script>

<style lang="scss" scoped>
@import "./index.scss";

.total {
  width: 49.5%;
  height: 296px;

  .content {
    margin-top: 16px;

    .item {
      height: 192px;
      padding: 22px 0 0 22px;
      box-sizing: border-box;
      border-radius: 16px;
      @include composing(2);
      &.item1 {
        background: #F0F8FF url("~@img/outStock/inStockWarning/outNum.png") right bottom no-repeat;
        background-size: 160px 100px;
      }

      &.item3 {
        background: #F0FAF7 url("~@img/outStock/inStockWarning/outPrice.png") right bottom no-repeat;
        background-size: 160px 100px;
      }


      .top {
        color: $fsColor4;
        font-size: 24px;
        @include textOverflow(100%);
        font-weight: bold;
      }

      .bottom {
        margin-top: 16px;
        color: $fsColor2;
        font-size: 14px;
        @include textOverflow(100%)
      }

    }
  }
}
</style>
